import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const TeamsPlayers = () => (
  <HowToLayout>
    <h2>Teams and Players</h2>
    <section className="documentation-section">
      <p>
        Managing teams and players with 4BAGR is quite simple. In this section
        we'll go over Team registration and roster management. Teams cannot be
        added or removed after a league schedule has been generated. You will
        need to delete the schedule, add/remove teams, then regenerate the
        schedule (careful, as this will reset the schedule and standings).
      </p>
      <div className="youtube-vid-wrap">
        <iframe
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          src="https://www.youtube.com/embed/See3hIl2YKo"
          title="Team Registration Walkthrough video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <section className="documentation-section">
      <h3 id="team-registration">Team registration</h3>
      <p>
        There's 2 ways to register teams for your league: Manual Team creation
        or Player registration. Manual team creation would be where the
        organizer creates the teams and adds players to teams themselves. Player
        Registration is a setting that you can enable to allow players to
        register their own teams and add their partners (4BAGR recommended
        route)
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="player-registration">Player Registration (Recommended)</h3>
      <p>
        Player Registration allows players to create their own team, add, and
        manage their own partners for the league. You, the organizer, have the
        ability to edit/remove any team registered for your league. You will
        still have the ability to add teams/players manually if necessary.
      </p>
      <ol>
        <li>Within 4BAGR: Navigate to your league settings screen</li>
        <li>Scroll down to 'Team Registration' - set this to 'Open'</li>
        <li>
          Players will now see a 'Join League' button on your league home screen
        </li>
      </ol>
      <p>
        Your league will stop allowing players to register once the 'Max # of
        Teams' limit is reached (setting within league settings screen)
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="manual-team-creation">Manual Team Creation</h3>
      <p>
        Manual Team Creation is where the league organizer creates all teams and
        adds all players to the teams. In the league settings, there's a section
        for 'Team Registration' - if this is set to 'Closed', then you will need
        to manually add teams and players.
      </p>
      <ol>
        <li>Within 4BAGR: Navigate to your league</li>
        <li>Select 'Roster' from the tab bar</li>
        <li>Click 'Add Team'</li>
        <li>Set Team Name</li>
        <li>From Roster Screen, you can add/remove players for that team</li>
      </ol>
      <p>Remember: Teams added by you can only be updated by you.</p>
    </section>
    <section className="documentation-section">
      <h3 id="editing-teams">Editing a Team</h3>
      <p>
        As the organizer, you have the ability to update the name, handicap, and
        players of the team at any time. As mentioned above, you are unable to
        remove/add teams after the schedule is generated, so keep this in mind.
      </p>
      <h5 style={{ marginBottom: 10 }}>Edit Name/Handicap</h5>
      <ol>
        <li>Within 4BAGR: Navigate to your League Standings Screen</li>
        <li>Select Team to Edit from the standings</li>
        <li>From the pop up modal, you can edit the team name and handicap</li>
      </ol>
      <h5 style={{ marginBottom: 10 }}>Edit Team Players</h5>
      <ol>
        <li>Navigate to Roster Screen</li>
        <li>
          You will see a 'Trash' Icon to delete the team (only if schedule is
          not yet generated)
        </li>
        <li>You will see an 'Add Player' button if the team is not full</li>
        <li>
          You will see a red 'minus' icon next to the player name to remove them
          from the team
        </li>
      </ol>
    </section>
  </HowToLayout>
);

export default TeamsPlayers;
